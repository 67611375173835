<template>
  <main class="transaction-details w-100">
    <section class="transaction-details__container">
      <h1 class="title transaction-details__title">
        {{ $t('TOUR_ORDER.TRANSACTION_DETAILS') }}
      </h1>
      <div class="slider-wrapper">
        <carousel
          class="cus-slider"
          :per-page="1"
          :mouse-drag="false"
          :navigationEnabled="true"
          :paginationEnabled="false"
          :navigation-next-label="nextLabel"
          :navigation-prev-label="prevLabel"
          v-if="transactionData.hotelData"
        >
          <slide
            v-for="(image, index) in transactionData.hotelData.HotelImages"
            :key="index"
          >
            <img :src="image.Url" alt="" />
          </slide>
        </carousel>
      </div>
      <div class="content-list text-direction-align">
        <!-- flight pairs -->
        <div class="each-content">
          <div
            v-if="!flight.isOpen"
            @click="toggleDetail('flight')"
            class="pointer collapse-parent"
          >
            <div
              v-for="(flight, index) in flight_pairs"
              :key="index"
              class="flight-header"
            >
              <div>
                {{ $t('TOUR_ORDER.ROUND_TRIP_FLIGHT') }}:
                <b>
                  {{ $t('TOUR_ORDER.TEL_AVIV') }} -
                  {{ transactionData.dealOrder.Destination.name_he }}</b
                >
              </div>
              <div class="flight-summary">
                <div class="flight-date">
                  <p>
                    <b>{{ flight.flightsPair.to.date | moment('D/M') }}</b>
                  </p>
                  <p>{{ flight.flightsPair.to.depTime }}</p>
                </div>
                <div class="black-minus"></div>
                <div class="flight-date">
                  <p>
                    <b>{{ flight.flightsPair.from.date | moment('D/M') }}</b>
                  </p>
                  <p>{{ flight.flightsPair.from.depTime }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="each-detail" v-else>
            <button class="content-close" @click="toggleDetail('flight')">
              <img src="/assets/img/close.svg" alt />
            </button>
            <h4 class="title text-center m-b-5">
              {{ $t('TOURISM_SUPPLIER.FLIGHTS') }}
            </h4>
            <div v-for="(flight, index) in flight_pairs" :key="index">
              <div class="flex-type1">
                <div class="text-center">
                  <img
                    src="/assets/img/easy-jet.png"
                    alt=""
                    class="flight-logo"
                  />
                  <p>{{ flight.flightsPair.to.airline }}</p>
                </div>
                <div class="h45px">
                  <p>
                    <b>{{ flight.flightsPair.to.date | moment('D/M') }}</b>
                  </p>
                  <p>{{ $t(tWeek(flight.flightsPair.to.date)) }}</p>
                </div>
                <div class="h45px">
                  <p>
                    <b>{{ flight.flightsPair.to.depTime }}</b>
                  </p>
                  <p>{{ flight.flightsPair.to.departureIATA }}</p>
                </div>
                <div class="h45px">
                  <p>{{ $t(tWeek(flight.flightsPair.to.date)) }}</p>
                </div>
                <div class="h45px">
                  <img src="/assets/img/arrow.png" alt="" />
                </div>
                <div class="h45px">
                  <p>
                    <b>{{ flight.flightsPair.to.arrTime }}</b>
                  </p>
                  <p>{{ flight.flightsPair.to.arrivalIATA }}</p>
                </div>
                <div class="h45px">
                  <p>
                    {{
                      $t(
                        arrWeek(
                          flight.flightsPair.to.date,
                          flight.flightsPair.to.depTime,
                          flight.flightsPair.to.arrTime,
                        ),
                      )
                    }}
                  </p>
                </div>
              </div>
              <hr class="m-25" />
              <div class="flex-type1">
                <div class="text-center">
                  <img
                    src="/assets/img/easy-jet.png"
                    alt=""
                    class="flight-logo"
                  />
                  <p>{{ flight.flightsPair.from.airline }}</p>
                </div>
                <div class="h45px">
                  <p>
                    <b>{{ flight.flightsPair.from.date | moment('D/M') }}</b>
                  </p>
                  <p>{{ $t(tWeek(flight.flightsPair.from.date)) }}</p>
                </div>
                <div class="h45px">
                  <p>
                    <b>{{ flight.flightsPair.from.depTime }}</b>
                  </p>
                  <p>{{ flight.flightsPair.from.departureIATA }}</p>
                </div>
                <div class="h45px">
                  <p>{{ $t(tWeek(flight.flightsPair.from.date)) }}</p>
                </div>
                <div class="h45px">
                  <img src="/assets/img/arrow.png" alt="" />
                </div>
                <div class="h45px">
                  <p>
                    <b>{{ flight.flightsPair.from.arrTime }}</b>
                  </p>
                  <p>{{ flight.flightsPair.from.arrivalIATA }}</p>
                </div>
                <div class="h45px">
                  <p>
                    {{
                      $t(
                        arrWeek(
                          flight.flightsPair.from.date,
                          flight.flightsPair.from.depTime,
                          flight.flightsPair.from.arrTime,
                        ),
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div class="px10 text-center">
                  <img src="/assets/img/cutlery.svg" alt="" />
                  <p>
                    {{ $t('COMMON.' + flight.flightsPair.Meal.toUpperCase()) }}
                  </p>
                </div>
                <div class="px10 text-center">
                  <img src="/assets/img/bag.svg" alt="" />
                  <p>
                    {{ flight.flightsPair.MaxWeightHandbag }}
                    {{ $t('COMMON.KG') }}
                  </p>
                </div>
                <div class="px10 text-center">
                  <img src="/assets/img/plane-stroke.svg" alt="" />
                  <p>__</p>
                </div>
                <div class="px10 text-center">
                  <img src="/assets/img/suitcase.svg" alt="" />
                  <p>
                    {{ flight.flightsPair.MaxWeight }} {{ $t('COMMON.KG') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- hotel block -->
        <div class="each-content" v-if="transactionData.hotelData">
          <div
            v-if="!hotel.isOpen"
            @click="toggleDetail('hotel')"
            class="pointer collapse-parent"
          >
            {{ $t('TOUR_ORDER.ACCOMMODATION') }}:
            <b>{{ transactionData.hotelData.HotelName }}</b>
          </div>
          <div class="each-detail" v-else>
            <button class="content-close" @click="toggleDetail('hotel')">
              <img src="/assets/img/close.svg" alt />
            </button>
            <div class="d-flex align-items-center">
              <h4>{{ transactionData.hotelData.HotelName }}</h4>
              <div class="hotel__stars">
                <svg
                  class="hotel__star"
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="25"
                  viewBox="0 0 26 25"
                  v-for="index in parseInt(transactionData.hotelData.HotelRate)"
                  :key="index"
                >
                  <defs:style>
                    .star-dark { fill: #002036; }
                  </defs:style>
                  <path
                    class="star-dark"
                    d="M32.158.857,28.97,7.321l-7.133,1.04a1.563,1.563,0,0,0-.864,2.666l5.161,5.029-1.221,7.1A1.561,1.561,0,0,0,27.178,24.8l6.381-3.354L39.941,24.8a1.563,1.563,0,0,0,2.265-1.645l-1.221-7.1,5.16-5.029a1.563,1.563,0,0,0-.864-2.666l-7.133-1.04L34.961.857a1.564,1.564,0,0,0-2.8,0Z"
                    transform="translate(-20.5 0.013)"
                  />
                </svg>
              </div>
            </div>
            <div class="hotel-exp">
              <p>
                <span>{{ $t('COMMON.ADDRESS') }}</span
                >: {{ transactionData.hotelData.HotelLocation.Address.street }}
              </p>
              <p>{{ transactionData.hotelData.HotelRemarks[0].FreeText }}</p>
              <div class="hotel__add-btn"></div>
            </div>
            <hr class="m-15" />
            <div
              class="
                d-flex
                justify-content-center
                align-items-center
                facility-group
              "
            >
              <div
                v-for="(facility, index) in transactionData.hotelData
                  .HotelFacilities"
                :key="index"
                class="px10 text-center facility"
              >
                <img :src="facility.Url" alt />
                <p>
                  {{ $t('FACILITY.' + facility.FacilityCode.toUpperCase()) }}
                </p>
              </div>
            </div>
            <button class="primary-btn hotel-detail-btn m-t-5">
              {{ $t('TOUR_ORDER.FULL_DETAIL_HOTEL') }}
            </button>
          </div>
        </div>
        <!-- passport block -->
        <div class="each-content" :key="domReload">
          <div
            v-if="!passports.isOpen"
            @click="toggleDetail('passports')"
            class="pointer collapse-parent"
          >
            {{ $t('TOUR_ORDER.PASSENGERS_VERHICLE_ROOMS') }}:
            {{ $t('TOURISM.PASSENGERS') }} {{ total_passportNumber }}
          </div>
          <div class="each-detail text-center" v-else>
            <button class="content-close" @click="toggleDetail('passports')">
              <img src="/assets/img/close.svg" alt />
            </button>
            <p class="text tour-passport__text">
              {{
                $t('TOUR_ORDER.PASSPORT_UPLOAD_STATE', {
                  num1: totalUploadedNumber,
                  num2: total_passportNumber,
                })
              }}
            </p>
            <div v-if="passportSel">
              <div
                v-for="(roomPassport, index) in room_passportArray"
                :key="index"
              >
                <h3 class="m-t-30 m-b-10">
                  {{ $t('TOURISM_PRODUCT.ROOM') }} {{ index + 1 }}
                </h3>
                <p>
                  {{
                    $t('TOUR_ORDER.PASSPORT_UPLOAD_STATE', {
                      num1: uploadedPassportNumber(roomPassport.id),
                      num2: roomPassport.value,
                    })
                  }}
                </p>
                <p>{{ getPaxDetail(roomPassport.pax) }}</p>
                <div
                  class="tour-passport__added"
                  v-if="passportData_receipts.length != 0"
                >
                  <div
                    v-for="(passport_item, index) in passportData_receipts[
                      roomPassport.id
                    ]"
                    :key="index"
                    class="tour-passport__added-item"
                  >
                    <div class="tour-passport__added-passport before-none">
                      <img
                        :src="passport_item.MRZdata.face_image"
                        class="tour-passport-image"
                      />
                      <!-- <img
                        src="../../../public/assets/img/passport-avatar.jpg"
                        class="tour-passport-image"
                      /> -->
                      <button
                        class="button tour-passport__added-pencil"
                        @click="
                          changePassImg(
                            roomPassport.id,
                            passport_item.MRZdata.MRZ,
                          )
                        "
                      >
                        <svg
                          class="tour-passport__svg-small"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="#000"
                        >
                          <path
                            d="M19.268,2.2,17.8.733a2.5,2.5,0,0,0-3.536,0L.5,14.5l-.5,4.46a.938.938,0,0,0,1.035,1.035l4.46-.5L19.268,5.733a2.5,2.5,0,0,0,0-3.536ZM15.55,7.684,6.8,16.436V15.079H4.922V13.2H3.565l8.752-8.752L15.55,7.684ZM4.928,18.305l-2.3.256L1.44,17.378l.256-2.3.776-.776H3.829v1.875H5.7V17.53ZM18.384,4.849,16.434,6.8,13.2,3.568l1.951-1.951a1.249,1.249,0,0,1,1.768,0l1.464,1.464A1.249,1.249,0,0,1,18.384,4.849Z"
                            transform="translate(-0.001 -0.001)"
                          />
                        </svg>
                      </button>
                      <button
                        class="button tour-passport__added-trash"
                        @click="
                          removePassImg(
                            roomPassport.id,
                            passport_item.MRZdata.MRZ,
                          )
                        "
                      >
                        <svg
                          class="tour-passport__svg-small"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="20"
                          viewBox="0 0 18 20"
                          fill="#000"
                        >
                          <path
                            d="M17.188,2.5H13.125L11.812.75a1.875,1.875,0,0,0-1.5-.75H7.188a1.875,1.875,0,0,0-1.5.75L4.375,2.5H.313A.313.313,0,0,0,0,2.813v.625a.312.312,0,0,0,.313.312h.738l1.3,14.543A1.875,1.875,0,0,0,4.215,20h9.07a1.875,1.875,0,0,0,1.867-1.707l1.3-14.543h.738a.313.313,0,0,0,.313-.312V2.813A.313.313,0,0,0,17.188,2.5Zm-10.5-1a.629.629,0,0,1,.5-.25h3.125a.629.629,0,0,1,.5.25l.75,1H5.937Zm7.219,16.68a.621.621,0,0,1-.621.57H4.215a.621.621,0,0,1-.621-.57L2.3,3.75H15.2Z"
                          />
                        </svg>
                      </button>
                    </div>
                    <p class="text tour-passport__added-text">
                      {{ $t('TOUR_ORDER.PASSENGER') }} {{ index + 1 }}
                    </p>
                  </div>
                </div>
                <div
                  class="tour-passport__added"
                  v-if="passportManualData.length != 0"
                >
                  <div
                    v-for="(passport_item, index) in passportManualData[
                      roomPassport.id
                    ]"
                    :key="'qqqq' + index"
                    class="tour-passport__added-item"
                  >
                    <div class="tour-passport__added-passport">
                      <button
                        class="button tour-passport__added-pencil"
                        @click="
                          editPassManualData(
                            roomPassport.id,
                            passport_item.passport_number,
                          )
                        "
                      >
                        <svg
                          class="tour-passport__svg-small"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="#000"
                        >
                          <path
                            d="M19.268,2.2,17.8.733a2.5,2.5,0,0,0-3.536,0L.5,14.5l-.5,4.46a.938.938,0,0,0,1.035,1.035l4.46-.5L19.268,5.733a2.5,2.5,0,0,0,0-3.536ZM15.55,7.684,6.8,16.436V15.079H4.922V13.2H3.565l8.752-8.752L15.55,7.684ZM4.928,18.305l-2.3.256L1.44,17.378l.256-2.3.776-.776H3.829v1.875H5.7V17.53ZM18.384,4.849,16.434,6.8,13.2,3.568l1.951-1.951a1.249,1.249,0,0,1,1.768,0l1.464,1.464A1.249,1.249,0,0,1,18.384,4.849Z"
                            transform="translate(-0.001 -0.001)"
                          />
                        </svg>
                      </button>
                      <button
                        class="button tour-passport__added-trash"
                        @click="
                          removePassManualData(
                            roomPassport.id,
                            passport_item.passport_number,
                          )
                        "
                      >
                        <svg
                          class="tour-passport__svg-small"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="20"
                          viewBox="0 0 18 20"
                          fill="#000"
                        >
                          <path
                            d="M17.188,2.5H13.125L11.812.75a1.875,1.875,0,0,0-1.5-.75H7.188a1.875,1.875,0,0,0-1.5.75L4.375,2.5H.313A.313.313,0,0,0,0,2.813v.625a.312.312,0,0,0,.313.312h.738l1.3,14.543A1.875,1.875,0,0,0,4.215,20h9.07a1.875,1.875,0,0,0,1.867-1.707l1.3-14.543h.738a.313.313,0,0,0,.313-.312V2.813A.313.313,0,0,0,17.188,2.5Zm-10.5-1a.629.629,0,0,1,.5-.25h3.125a.629.629,0,0,1,.5.25l.75,1H5.937Zm7.219,16.68a.621.621,0,0,1-.621.57H4.215a.621.621,0,0,1-.621-.57L2.3,3.75H15.2Z"
                          />
                        </svg>
                      </button>
                    </div>
                    <p class="text tour-passport__added-text">
                      {{ $t('TOUR_ORDER.PASSENGER') }} {{ index + 1 }}
                    </p>
                  </div>
                </div>
                <div v-if="isAddPassport(roomPassport)">
                  <div class="tour-passport__photo">
                    <svg
                      class="tour-passport__svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="20"
                      viewBox="0 0 23 20"
                    >
                      <path
                        d="M11.429,48.786a5.357,5.357,0,1,1,5.357-5.357A5.361,5.361,0,0,1,11.429,48.786Zm0-9.286a3.929,3.929,0,1,0,3.929,3.929A3.934,3.934,0,0,0,11.429,39.5ZM10,43.429A1.433,1.433,0,0,1,11.429,42a.714.714,0,0,0,0-1.429,2.86,2.86,0,0,0-2.857,2.857.714.714,0,1,0,1.429,0Zm4.478-10a.362.362,0,0,1,.335.232l.987,2.625h4.915a.716.716,0,0,1,.714.714V49.857a.716.716,0,0,1-.714.714H2.143a.716.716,0,0,1-.714-.714V37a.716.716,0,0,1,.714-.714h4.92l.9-2.393a.716.716,0,0,1,.67-.464h5.848m0-1.429H8.629a2.14,2.14,0,0,0-2,1.388l-.554,1.469H2.143A2.143,2.143,0,0,0,0,37V49.857A2.143,2.143,0,0,0,2.143,52H20.714a2.143,2.143,0,0,0,2.143-2.143V37a2.143,2.143,0,0,0-2.143-2.143H16.786l-.638-1.7A1.775,1.775,0,0,0,14.478,32Z"
                        transform="translate(0 -32)"
                      />
                    </svg>
                    <input
                      class="tour-passport__file"
                      type="file"
                      @change="handleImage(roomPassport.id)"
                      accept="image/*"
                      :ref="'file' + roomPassport.id"
                      multiple="multiple"
                    />
                  </div>
                  <p class="text tour-passport__text-passport">
                    {{ $t('TOUR_ORDER.PASSPORT_PHOTO') }}
                  </p>

                  <p class="text tour-passport__text-cant">
                    {{ $t('TOUR_ORDER.TOUR_PASSPORT_TXTCANT') }}
                  </p>

                  <button
                    class="link tour-passport__link"
                    @click="goManualToCreate(roomPassport.id)"
                  >
                    {{ $t('TOUR_ORDER.ENTER_MANUALLY') }}
                  </button>
                </div>
              </div>
            </div>

            <div class="wrapper" v-if="!passportSel">
              <div class="input">
                <input
                  type="file"
                  id="photoTest"
                  name="photo"
                  accept="image/png, image/jpeg"
                />
              </div>
              <div id="parsed"></div>
              <div id="detected"></div>
            </div>
            <div class="progress">
              <div class="gradient">
                <div class="progress-wrapper">
                  <div class="progress-text"></div>
                  <progress></progress>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- transportation block -->
        <div class="each-content" v-if="false">
          <div
            v-if="!transportation.isOpen"
            @click="toggleDetail('transportation')"
            class="pointer collapse-parent"
          >
            {{ $t('TOUR_ORDER.TRANSPORTATION_TITLE') }}
          </div>
          <div class="each-detail" v-else>
            <button
              class="content-close"
              @click="toggleDetail('transportation')"
            >
              <img src="/assets/img/close.svg" alt />
            </button>
            <p class="m-b-10">{{ $t('TOUR_ORDER.TRANSPORTATION_TITLE') }}</p>
            <p class="text-center">
              <b>{{ $t('TOUR_ORDER.GO') }}</b>
            </p>
            <div class="d-flex align-items-center m-b-10 p-relative">
              <div class="transportation-type">A</div>
              <div class="weight-700 fs15 px10">
                <p class="line-height1">שדה התעופה בודפשט</p>
                <p class="line-height1 color-blue1">לנובמבר, 2020 בשעה 16:35</p>
              </div>
              <div class="connect-line"></div>
            </div>
            <div class="d-flex align-items-center">
              <div class="transportation-type">B</div>
              <div class="weight-700 fs15 px10">
                <p class="line-height1">שדה התעופה בודפשט</p>
              </div>
            </div>
            <p class="text-center color-green m-t-20">
              <b>{{ $t('TOUR_ORDER.GO') }}</b>
            </p>
            <div class="d-flex align-items-center m-b-10 p-relative">
              <div class="transportation-type">B</div>
              <div class="weight-700 fs15 px10">
                <p class="line-height1">שדה התעופה בודפשט</p>
                <p class="line-height1 color-blue1">לנובמבר, 2020 בשעה 16:35</p>
              </div>
              <div class="connect-line"></div>
            </div>
            <div class="d-flex align-items-center">
              <div class="transportation-type">A</div>
              <div class="weight-700 fs15 px10">
                <p class="line-height1">שדה התעופה בודפשט</p>
              </div>
            </div>
            <p class="m-t-20">{{ $t('TOUR_ORDER.DISTANCE') }}:</p>
            <p>{{ $t('TOUR_ORDER.ESTIMATED_TRANSPORTATION_TIME') }}:</p>
          </div>
        </div>
        <div class="each-content" v-if="false">{{ $t('TOUR_ORDER.TICKETS') }}:</div>
      </div>
      <div class="px30" v-if="transactionData.dealOrder">
        <div class="value-item m-t-20">
          <p>{{ $t('TOUR_ORDER.PRICE_PER_PASSENGER') }}:</p>
          <p class="weight-700">
            <span>
              {{ $t('CURRENCY.' + transactionData.dealOrder.currency) }}
            </span>
            <span class="fs23 color-blue1">
              {{
                Math.round(
                  transactionData.dealOrder.deal_price / total_passportNumber,
                )
              }}
            </span>
          </p>
        </div>
        <div class="value-item">
          <p>{{ $t('TOUR_ORDER.TOTAL_PRICE') }}:</p>
          <p class="weight-700">
            <span>
              {{ $t('CURRENCY.' + transactionData.dealOrder.currency) }}
            </span>
            <span class="fs23 color-blue1">
              {{ transactionData.dealOrder.deal_price }}
            </span>
          </p>
        </div>
        <div class="exchange-rate">
          <p class="text-direction-align">
            * {{ $t('TOUR_ORDER.CURRENCEY_EXCHANGE_RATE') }}: 1{{
              $t('CURRENCY.' + transactionData.dealOrder.currency)
            }}
            = {{ transactionData.dealOrder.DealOrderItems[0].exchange_rate | formatPrice }}₪
          </p>
        </div>
        <template
          v-if="
            transactionData.dealOrder.discount_type == 'decrease' &&
            transactionData.dealOrder.discount_amount > 0
          "
        >
          <div class="value-item">
            <p>{{ $t('TOUR_ORDER.PRICE_BEFORE_DISCOUNT') }}:</p>
            <p class="weight-700">
            <span class="fs25 color-blue1"
            >{{ transactionData.dealOrder.price }}
            </span>
              <span>₪</span>
            </p>
          </div>
          <div class="value-item">
            <p>{{ $t('TOURISM_PRODUCT.DISCOUNT') }}:</p>
            <p class="weight-700">
            <span class="fs25 color-green"
            >{{ transactionData.dealOrder.discount_amount }}
            </span>
              <span>₪</span>
            </p>
          </div>
        </template>
        <div class="value-item">
          <p class="fs25 color-red1">{{ $t('TOUR_ORDER.PRICE_TO_PAID') }}:</p>
          <p class="weight-700">
            <span class="fs28 color-red2"
              >{{ transactionData.dealOrder.final_price }}
            </span>
            <span>₪</span>
          </p>
        </div>
        <!-- <router-link 
          :class="totalUploadedNumber != total_passportNumber ? 'disable-link' : ''"
          :to="{ name: 'order-confirmation', params: { transaction_id: transactionId, phone_number: phoneNumber } }"
          tag="a"
          class="link tour-payment__link">
          {{ $t("COMMON.CONTINUE") }}
        </router-link> -->
        <button
          class="link tour-payment__link"
          @click="goConfirm()"
          :class="
            totalUploadedNumber != total_passportNumber ? 'disable-link' : ''
          "
        >
          {{ $t('COMMON.CONTINUE') }}
        </button>
        <p class="total-price-exp text-direction-align">
          * {{ $t('TOUR_ORDER.TOTAL_PRICE_TXT') }}
        </p>
        <p class="fs18 color-red2">{{ $t('TOUR_ORDER.CANCEL_TRANSACTION') }}</p>
      </div>
    </section>

    <!-- error passport upload modal  -->
    <modal
      :width="300"
      :adaptive="true"
      class="CEModal modal-scroll-bar error-modal"
      name="errorModal"
    >
      <button class="button close-btn" @click="closePopup()">
        <img src="/assets/img/close.svg" alt />
      </button>
      <h3 class="fs16">{{ $t('ALERT.ERROR_PASSPORT_UPLOAD_TXT') }}</h3>
      <div class="d-flex justify-content-center modal-footer m-t-20 m-b-10">
        <button @click="closePopup()" class="cancel-btn">
          {{ $t('ALERT.TRY_AGAIN') }}
        </button>
        <button @click="manualInput()" class="primary-btn">
          {{ $t('TOUR_ORDER.ENTER_MANUALLY') }}
        </button>
      </div>
    </modal>

    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { settingService } from '../../services/setting.service';

export default {
  name: 'OrderDetails',
  components: {
    Loading,
  },
  computed: {
    ...mapState({
      loading: (state) => state.tour_order.loading,
      passportData_receipts: (state) => state.tour_order.passportData_receipts,
      passportManualData: (state) => state.tour_order.passportManualData,
      transactionData: (state) => state.tour_order.transactionData,
      passportImageTempData: (state) => state.tour_order.passportImageTempData,
    }),
    transactionId() {
      return localStorage.getItem('transactionId');
    },
    phoneNumber() {
      return localStorage.getItem('phone_number');
    },
    totalUploadedNumber() {
      let total_uploadedNumber = 0;
      this.room_passportArray.forEach((ele) => {
        total_uploadedNumber += this.uploadedPassportNumber(ele.id);
      });
      return total_uploadedNumber;
    },
  },
  async created() {
    const transaction_id = this.$route.params.transaction_id;
    const phoneNumber = this.$route.params.phone_number;
    await this.getTransactionData({
      id: transaction_id,
      phoneNumber: phoneNumber,
    });
    if (this.transactionData) {
      /*  set the total passportNumber */
      this.transactionData.dealOrder.DealOrderItems.forEach((ele) => {
        this.total_passportNumber += ele.paxComplect.length;
        this.room_passportArray.push({
          value: ele.paxComplect.length,
          id: ele.id,
          pax: ele.paxComplect,
        });
      });

      /*  create the flight pairs array  */
      this.flight_pairs = this.transactionData.dealOrder.DealOrderItems.reduce(
        (flight, current) => {
          const x = flight.find(
            (item) =>
              item.from_flight_id === current.from_flight_id &&
              item.to_flight_id === current.to_flight_id,
          );
          if (!x) {
            return flight.concat([current]);
          } else {
            return flight;
          }
        },
        [],
      );
    }

    settingService.getPassportSelect().then((r) => {
      if (!r.data.error && r.data.content) {
        if (r.data.content.id == '1') {
          this.passportSel = 1;
        } else {
          this.passportSel = 0;
        }
      }
    });
  },
  data: () => {
    return {
      nextLabel: "<img class src='/assets/img/chevron.svg' />",
      prevLabel: "<img class src='/assets/img/chevron.svg' />",
      domReload: true,
      flight: {
        isOpen: false,
      },
      hotel: {
        isOpen: false,
      },
      passports: {
        isOpen: true,
      },
      transportation: {
        isOpen: false,
      },
      tickets: {
        isOpen: false,
      },
      total_passportNumber: 0,
      room_passportArray: [],
      flight_pairs: [],
      passport_details: [],
      passportSel: 1,
    };
  },
  mounted() {
    localStorage.setItem('transactionId', this.$route.params.transaction_id);
    localStorage.setItem('phone_number', this.$route.params.phone_number);
    // this.$refs.js_backbtn.classList.add("hide-item");
    document.addEventListener('getPassportData', (e) => {
      console.log(e.detail.result.parsed.fields);
      this.$store.commit('tour_order/setNewPassportImageTempData', {
        passData: e.detail.result,
      });
    });
  },
  methods: {
    ...mapActions('tour_order', {
      uploadPassImage: 'uploadPassImage',
      removePassData: 'removePassData',
      changePassImageData: 'changePassImageData',
      removePassMData: 'removePassMData',
      getTransactionData: 'getTransactionData',
      confirmTransaction: 'confirmTransaction',
      setPassportDetail: 'setPassportDetail',
    }),

    ...mapActions('alert', {
      errorAlert: 'error',
    }),
    /* calculate day of week */
    tWeek(wDay) {
      return 'DATE.' + this.$moment(wDay).format('ddd').toUpperCase();
    },
    arrWeek(depDate, depTime, arrTime) {
      let arrDate = depDate;
      if (depTime > arrTime) {
        arrDate = this.$moment(depDate).add(1, 'days').format('DD');
      }
      return 'DATE.' + this.$moment(arrDate).format('ddd').toUpperCase();
    },
    toggleDetail(id) {
      this[id].isOpen = !this[id].isOpen;
    },

    /* go to manual passport page to add new passport  */
    goManualToCreate(id) {
      localStorage.setItem('roomId', id);
      this.$router.push({ name: 'passport-detail', params: { id: 'create' } });
    },
    manualInput() {
      this.$router.push({ name: 'passport-detail', params: { id: 'create' } });
    },

    /* uploaded passport number  */
    uploadedPassportNumber(id) {
      if (
        this.passportManualData.length != 0 ||
        this.passportData_receipts.length != 0
      ) {
        let uploaded_number = 0;
        if (typeof this.passportManualData[id] != 'undefined') {
          uploaded_number += this.passportManualData[id].length * 1;
        }
        if (typeof this.passportData_receipts[id] != 'undefined') {
          uploaded_number += this.passportData_receipts[id].length * 1;
        }
        return uploaded_number;
      }
      return 0;
    },

    /* is add passport */
    isAddPassport(roomPassport) {
      let manual_passportNumber = 0;
      let upload_passportNumber = 0;
      if (typeof this.passportManualData[roomPassport.id] != 'undefined') {
        manual_passportNumber = this.passportManualData[roomPassport.id].length;
      }
      if (typeof this.passportData_receipts[roomPassport.id] != 'undefined') {
        upload_passportNumber =
          this.passportData_receipts[roomPassport.id].length;
      }
      if (
        manual_passportNumber * 1 + upload_passportNumber * 1 >=
        roomPassport.value
      ) {
        return false;
      }
      return true;
    },

    /* get passenger detail from paxComplect */
    getPaxDetail(pax) {
      const adult_num = (pax.match(new RegExp('A', 'g')) || []).length;
      const child_num = (pax.match(new RegExp('C', 'g')) || []).length;
      const infant_num = (pax.match(new RegExp('I', 'g')) || []).length;
      const pax_string =
        adult_num +
        ' ' +
        this.$t('TOURISM.ADULTS') +
        ', ' +
        child_num +
        ' ' +
        this.$t('TOURISM.CHILDREN') +
        ', ' +
        infant_num +
        ' ' +
        this.$t('TOURISM_PRODUCT.BABIES');
      return pax_string;
    },

    /* error modal on uploading passport */
    closePopup() {
      this.$modal.hide('errorModal');
    },

    async handleImage(id) {
      let formData = new FormData();
      for (var i = 0; i < this.$refs['file' + id][0].files.length; i++) {
        let file = this.$refs['file' + id][0].files[i];
        formData.append('scan_images[' + i + ']', file);
      }

      localStorage.setItem('roomId', id);
      await this.uploadPassImage({ scan_images: formData });

      if (!this.passportImageTempData) {
        this.$modal.show('errorModal');
      }
    },

    removePassImg(roomId, mrz) {
      localStorage.setItem('roomId', roomId);
      this.removePassData({ mrz: mrz });
      this.domReload = +new Date();
    },
    changePassImg(roomId, mrz) {
      localStorage.setItem('roomId', roomId);
      this.changePassImageData({ mrz: mrz });
    },
    removePassManualData(roomId, id) {
      localStorage.setItem('roomId', roomId);
      this.removePassMData({ id: id });
      this.domReload = +new Date();
    },
    editPassManualData(roomId, id) {
      localStorage.setItem('roomId', roomId);
      this.$router.push('/passport-detail/' + id);
    },

    /* go confirmation page */
    goConfirm() {
      if (this.totalUploadedNumber == this.total_passportNumber) {
        for (let key in this.room_passportArray) {
          const roomId = this.room_passportArray[key].id;
          if (typeof this.passport_details[roomId] == 'undefined') {
            this.passport_details[roomId] = [];
          }
          if (typeof this.passportData_receipts[roomId] != 'undefined') {
            this.passportData_receipts[roomId].forEach((ele, eKey) => {
              let is_customer = 0;
              if (eKey == 0) {
                is_customer = 1;
              }
              let each_passport = {
                first_name: ele.first_name,
                last_name: ele.last_name,
                is_customer: is_customer,
                passport_number: ele.passport_number,
                gender: ele.gender,
                date_of_birth: moment(ele.birthday).format('YYYY-MM-DD'),
                passport_country: ele.passport_country,
                id_number: ele.id_number,
                validity: ele.validity
                  ? moment(ele.validity).format('YYYY-MM-DD')
                  : null,
              };
              this.passport_details[roomId].push(each_passport);
            });
          }
          if (typeof this.passportManualData[roomId] != 'undefined') {
            this.passportManualData[roomId].forEach((ele, eKey) => {
              let is_customer = 0;
              // if (eKey == 0) {
              //   is_customer = 1;
              // }
              let each_passport = {
                first_name: ele.first_name,
                last_name: ele.last_name,
                is_customer: is_customer,
                passport_number: ele.passport_number,
                gender: ele.gender,
                date_of_birth: moment(ele.birthday).format('YYYY-MM-DD'),
                passport_country: ele.passport_country,
                id_number: ele.id_number,
                validity: ele.validity
                  ? moment(ele.validity).format('YYYY-MM-DD')
                  : null,
              };
              this.passport_details[roomId].push(each_passport);
            });
          }
        }
        let target_d = {};
        this.passport_details.forEach((item, index) => {
          if (item) {
            target_d[index] = item;
          }
        });
        this.setPassportDetail({
          pData: target_d,
        });
        localStorage.setItem('passportData', JSON.stringify(target_d));
        localStorage.setItem(
          'finalPrice',
          this.transactionData.dealOrder.final_price,
        );
        const id = localStorage.getItem('transactionId');
        const phoneNumber = localStorage.getItem('phone_number');

        this.$router.push({
          name: 'order-confirmation',
          params: { transaction_id: id, phone_number: phoneNumber },
        });
      } else {
        this.passports.isOpen = true;
        this.errorAlert(this.$t('ALERT.REQUIRE_PASSPORT_TXT'));
      }
    },
  },
};
</script>
<style lang="scss">
.cus-slider {
  .VueCarousel-slide {
    position: relative;
    color: #fff;
    font-family: Arial;
    font-size: 24px;
    text-align: center;
    min-height: 100px;
    img {
      width: 100%;
      height: 50vw;
      object-fit: cover;
    }
  }

  .label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .VueCarousel-navigation-button {
    opacity: 1;
    background-color: #eaebed;
    height: 100%;
    &:hover {
      background-color: #fff;
    }
    &.VueCarousel-navigation-next {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
.transaction-details {
  .VueCarousel-navigation-button {
    width: 55px;
    img {
      width: 18px;
    }
  }
}
.tour-passport__added {
  flex-wrap: wrap;
}

.before-none::before {
  display: none;
}

.tour-passport-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.error-modal {
  .vm--modal {
    padding: 15px 10px;
  }
}
</style>
<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
.disable-link {
  // pointer-events:none;
  cursor: not-allowed;
}
.transaction-details {
  padding-top: 25px;
  .transaction-details__container {
    padding: 0;
  }
}
.value-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  p {
    font-size: 17px;
  }
}
.exchange-rate {
  margin-top: 30px;
  width: 100%;
  border-bottom: 1px solid #b2bbc2;
  // text-align: left;
  text-align: right;
  p {
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.total-price-exp {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content-list {
  .each-content {
    background-color: #fff;
    border-bottom: 2px solid #dfe0e3;
    padding: 5px 10px;
    color: #002036;
    position: relative;
    .collapse-parent {
      padding-left: 40px;
      padding-right: unset;
      &::before {
        content: '';
        width: 10px;
        height: 18px;
        background: url(/assets/img/chevron.svg) no-repeat;
        background-size: contain;
        background-position: center;
        position: absolute;
        top: 8px;
        left: 20px;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -webkit-transition: 0.3s;
        transition: 0.3s;
      }
    }
    .flight-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .flight-summary {
        display: flex;
        align-items: center;
        margin: -5px 0;
        .flight-date {
          b {
            font-size: 13px;
          }
          p {
            line-height: 13px;
            font-size: 14px;
          }
        }
      }
    }
    .black-minus {
      width: 12px;
      height: 2px;
      margin: 0 5px;
      background-color: #000;
    }
    .each-detail {
      position: relative;
      // padding: 10px;
      .flight-logo {
        width: 50px;
        height: 50px;
      }
      hr {
        border: none;
        border-bottom: 1px solid #b2bbc2;
      }
      .hotel__stars {
        margin: 0 10px;
        justify-content: flex-start;
      }
      .hotel__star {
        width: 17px;
        height: 17px;
        margin: 0 2px;
      }
      .hotel-exp {
        position: relative;
      }
      .hotel__add-btn {
        width: 20px;
        height: 20px;
        background: #002036;
        position: relative;
        margin-left: 10px;
        transition: 0.3s;
        cursor: pointer;
        position: absolute;
        left: 0;
        right: unset;
        bottom: 0;

        &:before {
          content: '';
          width: 15px;
          height: 15px;
          background: url(/assets/img/plus.svg) no-repeat;
          background-size: contain;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .hotel-detail-btn {
        border-radius: 5px;
        font-size: 16px;
        font-weight: 100;
        height: 32px;
      }
      .transportation-type {
        font-weight: 700;
        border-radius: 50%;
        background-color: #ff6800;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        font-size: 20px;
      }
      .connect-line {
        position: absolute;
        height: 10px;
        border-left: 1px solid #ff6800;
        bottom: -10px;
        right: 17px;
        left: unset;
      }
      .facility-group {
        flex-wrap: wrap;
        .facility {
          width: 25%;
          margin-bottom: 10px;
          height: 74px;
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.slider-wrapper {
  padding: 0 55px;
  margin: 20px 0;
}

.content-close {
  position: absolute;
  top: 2px;
  left: 2px;
  right: unset;
}
.ltr-type {
  .content-close {
    right: 2px;
    left: unset;
  }
  .hotel__add-btn {
    right: 0 !important;
    left: unset !important;
  }
  .connect-line {
    right: unset !important;
    left: 17px !important;
  }
  .error-modal {
    .close-btn {
      right: 10px;
      left: unset;
    }
  }
  .content-list {
    .each-content {
      .collapse-parent {
        padding-right: 40px;
        padding-left: unset;
      }
    }
  }
}

.h45px {
  height: 45px;
}

.tour-payment__link {
  margin: 0 auto;
  margin-top: 20px;
}

.tour-passport__link {
  margin: 0 auto;
  margin-top: 13px;
}

.error-modal {
  .close-btn {
    position: absolute;
    top: 3px;
    left: 10px;
    right: unset;
    img {
      width: 14px;
    }
  }
  .primary-btn {
    font-size: 16px;
    width: auto;
    padding: 0 5px;
    height: 36px;
    margin: 0 10px;
  }
  .cancel-btn {
    padding: 0 5px;
    margin: 0 10px;
  }
}
div.progress.visible {
  visibility: visible;
}

div.progress {
  visibility: hidden;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 999;
}

div.gradient {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: fixed;
  top: 0;
  left: 0;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.32+100 */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 100%
  ); /* FF3.6-15 */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.25) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#52000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
div.progress-wrapper {
  font-size: 2em;
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  border: 2px solid black;
  background: white;
  box-shadow: 0.5em 0.5em 0.5em rgba(0, 0, 0, 0.25);
  border-radius: 0.2em;
}

progress {
  width: 100%;
  margin: 0.2em;
}

div.wrapper {
  padding: 5px;
}

div.wrapper,
div.wrapper > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#parsed .error {
  padding: 0px 1em;
  max-width: 60%;
}

div#detected {
  margin-bottom: 1em;
  flex-direction: column-reverse;
}

canvas {
  max-width: 100%;
  margin: 5px;
}

canvas[title='crop'] {
  max-width: 100%;
  margin: 0px 5px;
}
</style>